import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=d0b7baea&scoped=true"
import script from "./reset.vue?vue&type=script&lang=js"
export * from "./reset.vue?vue&type=script&lang=js"
import style0 from "./reset.vue?vue&type=style&index=0&id=d0b7baea&prod&lang=css"
import style1 from "./reset.vue?vue&type=style&index=1&id=d0b7baea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0b7baea",
  null
  
)

export default component.exports